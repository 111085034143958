button:focus {
  outline: none;
}

.bg-gradient {
  background-image: linear-gradient(
    261.92deg,
    #484fff -26.08%,
    #f53356 123.74%
  ) !important;
}

.btn-gradient {
  background-image: linear-gradient(
    261.92deg,
    #484fff -26.08%,
    #f53356 123.74%
  ) !important;
  box-shadow: 0px 6px 12px rgba(192, 231, 253, 0.5);
  border: none;
}

.btn-white {
  position: relative;
}

.btn-white::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -1;
}

.text-gradient {
  background: -webkit-linear-gradient(#484fff -26.08%, #f53356 123.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.otpInput {
  width: 50px !important;
  height: 50px;
}

.PhoneInputCountry {
  justify-content: center;
  width: 50px;
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}
