.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: #a953ff;
}
.audio-title {
  color: #000;
  font-weight: 600;
}

.react-jinke-music-player-main .music-player-lyric {
  color: #a953ff;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  display: none;
}
.react-jinke-music-player-main.light-theme .play-mode-title {
  color: #a953ff;
}
